body {
    font-size: 14px !important;
}

main {
    width: 100%;
    padding: 48px 0;
    background: #EFEFF8 !important;
}

main.main-list {
    background: #fff !important;
    border: 1px solid #eee;

}
main.header-join {
    width: 100%;
    padding: 0;
    background: #fff !important;
}
main.header-list {
    width: 100%;
    padding: 0;
    background: #fff !important;
}

section {
    width: 860px;
    min-width: 860px;
    max-width: 860px;
    margin: 0 auto;
    /* 구분을 위한 임시 border */
    /* border: 1px solid #eee; */
}
section.header-join {
    padding: 24px 0;
}

section.header-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: eee;
}



h1 {
    font-size: 40px;
    font-weight: 700;
}

h2 {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 700;
}

.MuiFormControlLabel-label {
    font-size: 14px !important;
}

.MuiDataGrid-cell:focus {
    outline: 0 !important;
}